.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Container-main {
  min-width: 20%;
  max-width: 100%;
  max-height: auto;
  color: green;
  background-color: black;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.logoGrid {
  position: relative;
  width: 20%;
  padding-top: 10px;
  padding-left: 10px;
}

.titleGrid {
  position: relative;
  width: 80%;
  color: white;
  float: left;
  padding-top: 50px;
  padding-left: -20px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
  font-size:x-large;
}

.mainGrid {
  height: 100%;
  min-height: 1080px;
  background-image: url('./img/Summit Background.jpg');
}

.headerGrid {
  height: 100%;
  
}
.upperGrid {
  height: 70%;
  width: 100%;
  
}
.lowerGrid {
  height: 30%;
  width: 100%;
  background-image: url('./img/Summit Background.jpg');
  
}

.innerGridLeft {
  text-align: center;
  padding-top: 15%;
  width: 50%;
  position: relative;
  color: white;

}

.innerGridLeftNoShow {
  text-align: center;
  padding-top: 15%;
  width: 50%;
  position: relative;
  color: white;
  display: none;
}

.innerGridRight {
  text-align: center;
  padding-top: 15%;
  width: 50%;
  position:relative;
  color: white;
}

.innerGridRightNoShow {
  text-align: center;
  padding-top: 15%;
  width: 50%;
  position:relative;
  color: white;
  display: none;
}

.introDescriptionShow {
  text-align: center;
  color: white;
  padding-bottom: 20px;
  padding-top: 10%;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
  font-size:large;
}

.introDescriptionNoShow {
  text-align: center;
  color: white;
  padding-bottom: 20px;
  padding-top: 20px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
  font-size:large;
  display: none;
}

.brand-logo {
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.createNFC {
  text-align: center;
  padding-top: 5%;
  width: 50%;
  position:relative;
  color: white;
  display: none;
}

.createNFCShow {
  text-align: center;
  padding-top: 5%;
  width: 50%;
  position:relative;
  color: white;
}

.nfcTextBot {
  text-align: center;
  color: white;
  padding-bottom: 20px;
  padding-top: 20px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
  font-size:large;

}

.chimeGridNoShow {
  text-align: center;
  padding-top: 5%;
  width: 50%;
  position:relative;
  color: white;
  display: none;
}

.chimeGridShow {
  text-align: center;
  padding-top: 5%;
  width: 50%;
  color: white;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
  font-size:large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
